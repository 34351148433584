import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import { Flex } from '@chakra-ui/react';
import { useRouter } from 'next/router';
const LoginButtons = dynamic(() => import('./components/LoginButtons'), {
  ssr: false
});
const InlinePlatformToggle = dynamic(
  () => import('./components/InlinePlatformToggle'),
  { ssr: false }
);

export const BillingLink: FC = () => {
  const { asPath } = useRouter();
  const isPracticeMode = /\/practice/gi.test(asPath);

  return (
    <Flex mr={10}>
      {!isPracticeMode && <InlinePlatformToggle />}
      <LoginButtons />
    </Flex>
  );
};

export default BillingLink;
